/** @format */

@import "~antd/dist/antd.less";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

html {
  display: grid;
}

.ant-popover-inner-content {
  padding: 0px !important;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  display: none;
}
.advice {
  display: none;
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  display: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@media only screen and (min-device-width: 768px) and (orientation: portrait) {
  .phone,
  .message {
    display: block;
  }

  .advice {
    display: flex;
    height: 100%;
    align-items: center;
    flex-flow: column;
    place-content: center;
  }

  .app {
    display: none;
  }

  html,
  body {
    background: black;
    height: 100%;
    width: 100%;
  }

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (orientation: landscape) {
  .phone,
  .message,
  .advice {
    display: none;
  }
}
@media only screen and (max-device-width: 767px) {
  .phone,
  .message,
  .advice {
    display: none;
  }
}

@-moz-document url-prefix() {
  html {
    display: grid;
  }
}
.ant-picker-cell-week {
  display: none !important;
}

.ant-picker-week-panel [aria-label="empty cell"] {
  display: none;
}

.public-DraftStyleDefault-block {
  margin: 0.1em 0px !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #576ab0;@layout-header-background: #576ab0;@layout-body-background: #f0f2f5;@font-family: 'Quicksand', 'Open Sans';